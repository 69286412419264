import React from 'react';
import { Link } from 'react-router-dom';
import { FaLock, FaChartLine, FaVoteYea, FaBolt } from 'react-icons/fa';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { motion } from 'framer-motion';

function FeatureCard({ icon, title, description }) {
  return (
    <motion.div 
      whileHover={{ scale: 1.02 }}
      className="p-6 rounded-xl bg-gray-800 border border-gray-700 hover:border-emerald-400/50 transition-all"
    >
      <div className="text-emerald-400 text-3xl mb-4">
        {icon}
      </div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-400">{description}</p>
    </motion.div>
  );
}

function StatsCard({ value, label }) {
  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      className="p-6 rounded-xl bg-gray-800 border border-gray-700"
    >
      <div className="text-3xl font-bold text-emerald-400 mb-2">{value}</div>
      <div className="text-gray-400">{label}</div>
    </motion.div>
  );
}

function Landing() {
  return (
    <div className="min-h-screen bg-gray-900 flex flex-col text-white">
      <Navbar />

      {/* Hero Section */}
      <section className="pt-32 pb-20">
        <div className="container mx-auto px-4">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="max-w-4xl mx-auto text-center"
          >
            <h1 className="text-5xl md:text-6xl font-bold mb-6">
              <motion.span 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
                className="text-emerald-400"
              >
                Vote-Escrow DEX
              </motion.span> on Kaspa Network
            </h1>
            <motion.p 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
              className="text-xl text-gray-400 mb-8"
            >
              Trade, stake, and shape the future of DeFi on Kaspa. Earn rewards while having a voice in protocol governance.
            </motion.p>
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.7 }}
              className="flex gap-4 justify-center"
            >
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <Link 
                  to="/swap"
                  className="bg-emerald-400 text-gray-900 px-6 py-3 rounded-lg hover:bg-emerald-500 transition-colors"
                >
                  Start Trading
                </Link>
              </motion.div>
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <a 
                  href="/"
                  className="border border-emerald-400 text-emerald-400 px-6 py-3 rounded-lg hover:bg-emerald-400/10 transition-colors"
                >
                  Read Docs
                </a>
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="py-20 bg-gray-800/30">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            <StatsCard value="$0M" label="Total Value Locked" />
            <StatsCard value="0K" label="Total Trades" />
            <StatsCard value="0K" label="Active Users" />
            <StatsCard value="0" label="Governance Proposals" />
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="py-20">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-12"
          >
            <h2 className="text-3xl font-bold text-center mb-4 text-emerald-400">
              Why Choose Kaspacho?
            </h2>
            <p className="text-center text-gray-400 mb-12 max-w-2xl mx-auto">
              Experience the next generation of decentralized trading with our unique vote-escrow model
            </p>
          </motion.div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <FeatureCard 
              icon={<FaLock />}
              title="Secure Trading"
              description="Built on Kaspa's secure and scalable proof-of-work blockchain"
            />
            <FeatureCard 
              icon={<FaVoteYea />}
              title="Vote-Escrow Model"
              description="Lock tokens to earn voting power and shape protocol decisions"
            />
            <FeatureCard 
              icon={<FaBolt />}
              title="Lightning Fast"
              description="Near-instant trades with Kaspa's block time of 1 second"
            />
            <FeatureCard 
              icon={<FaChartLine />}
              title="Earn Rewards"
              description="Provide liquidity and participate in governance to earn rewards"
            />
          </div>
        </div>
      </section>

      {/* Roadmap Section */}
      <section id="roadmap" className="py-20 bg-gray-800/30">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-12"
          >
            <h2 className="text-3xl font-bold text-center mb-4 text-emerald-400">
              Roadmap
            </h2>
            <p className="text-center text-gray-400 mb-12 max-w-2xl mx-auto">
              Our journey to build the leading DEX on Kaspa
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="p-6 rounded-xl bg-gray-800 border border-gray-700">
              <h3 className="text-xl font-bold mb-4 text-emerald-400">Q1 2025</h3>
              <ul className="space-y-3">
                <li className="flex items-start gap-2">
                  <span className="text-emerald-400 mt-1">•</span>
                  <span className="text-white">Platform Launch</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-emerald-400 mt-1">•</span>
                  <span className="text-white">Basic Swap Functionality</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-emerald-400 mt-1">•</span>
                  <span className="text-white">Token Launch</span>
                </li>
              </ul>
            </div>
            <div className="p-6 rounded-xl bg-gray-800 border border-gray-700">
              <h3 className="text-xl font-bold mb-4 text-emerald-400">Q2 2025</h3>
              <ul className="space-y-3">
                <li className="flex items-start gap-2">
                  <span className="text-emerald-400 mt-1">•</span>
                  <span className="text-white">Vote-Escrow Implementation</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-emerald-400 mt-1">•</span>
                  <span className="text-white">Governance Portal</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-emerald-400 mt-1">•</span>
                  <span className="text-white">Advanced Trading Features</span>
                </li>
              </ul>
            </div>
            <div className="p-6 rounded-xl bg-gray-800 border border-gray-700">
              <h3 className="text-xl font-bold mb-4 text-emerald-400">Q3 2025</h3>
              <ul className="space-y-3">
                <li className="flex items-start gap-2">
                  <span className="text-emerald-400 mt-1">•</span>
                  <span className="text-white">Analytics Dashboard</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-emerald-400 mt-1">•</span>
                  <span className="text-white">Mobile App Beta</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-emerald-400 mt-1">•</span>
                  <span className="text-white">Cross-chain Integration</span>
                </li>
              </ul>
            </div>
            <div className="p-6 rounded-xl bg-gray-800 border border-gray-700">
              <h3 className="text-xl font-bold mb-4 text-emerald-400">Q4 2025</h3>
              <ul className="space-y-3">
                <li className="flex items-start gap-2">
                  <span className="text-emerald-400 mt-1">•</span>
                  <span className="text-white">Advanced Governance</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-emerald-400 mt-1">•</span>
                  <span className="text-white">Mobile App Launch</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-emerald-400 mt-1">•</span>
                  <span className="text-white">Protocol Expansion</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="container mx-auto px-4 text-center"
        >
          <h2 className="text-3xl font-bold mb-4 text-emerald-400">
            Ready to Start Trading?
          </h2>
          <p className="text-gray-400 mb-8 max-w-2xl mx-auto">
            Join the future of decentralized trading on Kaspa. Start trading with Kaspacho today.
          </p>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Link 
              to="/swap"
              className="bg-emerald-400 text-gray-900 px-6 py-3 rounded-lg hover:bg-emerald-500 transition-colors inline-block"
            >
              Launch App
            </Link>
          </motion.div>
        </motion.div>
      </section>

      <Footer />
    </div>
  );
}

export default Landing; 