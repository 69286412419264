import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaHome, FaExclamationTriangle } from 'react-icons/fa';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function NotFound() {
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col">
      <Navbar />
      
      <motion.div 
        className="flex-grow container mx-auto px-4 py-8 flex items-center justify-center"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="text-center">
          {/* 404 Icon */}
          <motion.div 
            variants={itemVariants}
            className="mb-8"
          >
            <FaExclamationTriangle className="text-emerald-400 text-6xl mx-auto" />
          </motion.div>

          {/* Error Code */}
          <motion.h1 
            variants={itemVariants}
            className="text-8xl font-bold text-white mb-4"
          >
            404
          </motion.h1>

          {/* Error Message */}
          <motion.h2 
            variants={itemVariants}
            className="text-2xl text-gray-400 mb-8"
          >
            Oops! Page Not Found
          </motion.h2>

          {/* Description */}
          <motion.p 
            variants={itemVariants}
            className="text-gray-500 mb-8 max-w-md mx-auto"
          >
            The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
          </motion.p>

          {/* Back to Home Button */}
          <motion.div variants={itemVariants}>
            <Link 
              to="/" 
              className="inline-flex items-center gap-2 bg-emerald-400 hover:bg-emerald-500 text-gray-900 font-bold py-3 px-6 rounded-xl transition-colors"
            >
              <FaHome />
              Back to Home
            </Link>
          </motion.div>
        </div>
      </motion.div>

      <Footer />
    </div>
  );
}

export default NotFound; 