import React from 'react';
import { motion } from 'framer-motion';
import { FaWallet, FaChartLine, FaExchangeAlt, FaHistory } from 'react-icons/fa';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { Line, Doughnut } from 'react-chartjs-2';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

// Enregistrer les composants Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

function AssetCard({ asset }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ scale: 1.02 }}
      className="bg-gray-800 rounded-xl p-6 border border-gray-700"
    >
      <div className="flex items-center gap-4">
        <img src={asset.icon} alt={asset.name} className="w-10 h-10 rounded-full" />
        <div className="flex-grow">
          <h3 className="text-lg font-semibold text-white">{asset.name}</h3>
          <p className="text-gray-400">{asset.symbol}</p>
        </div>
        <div className="text-right mr-4">
          <div className="flex flex-col items-end">
            <p className="text-lg font-semibold text-white">${asset.value}</p>
            <p className="text-sm text-gray-400">{asset.quantity} {asset.symbol}</p>
            <p className={`text-sm ${asset.change >= 0 ? 'text-green-400' : 'text-red-400'}`}>
              {asset.change >= 0 ? '+' : ''}{asset.change}%
            </p>
          </div>
        </div>
        <div className="flex gap-2">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="px-3 py-1.5 bg-emerald-400 text-gray-900 rounded-lg text-sm font-semibold hover:bg-emerald-500"
            onClick={() => window.location.href = '/swap'}
          >
            Swap
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="px-3 py-1.5 border border-emerald-400 text-emerald-400 rounded-lg text-sm font-semibold hover:bg-emerald-400/10"
            onClick={() => window.location.href = '/pool/add'}
          >
            Add Liquidity
          </motion.button>
        </div>
      </div>
    </motion.div>
  );
}

function StatCard({ icon, title, value }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-gray-800 rounded-xl p-6 border border-gray-700"
    >
      <div className="flex items-center gap-4">
        <div className="p-3 bg-emerald-400/10 rounded-lg text-emerald-400">
          {icon}
        </div>
        <div>
          <p className="text-gray-400">{title}</p>
          <p className="text-xl font-semibold text-white">{value}</p>
        </div>
      </div>
    </motion.div>
  );
}

function Dashboard() {
  // Mock data avec quantités ajoutées
  const assets = [
    { 
      name: 'Kaspa', 
      symbol: 'KAS', 
      value: '1,234.56', 
      quantity: '12,345.67',
      change: 5.67, 
      icon: '/tokens-icons/kas.svg' 
    },
    { 
      name: 'Ethereum', 
      symbol: 'ETH', 
      value: '2,345.67', 
      quantity: '1.234',
      change: -2.34, 
      icon: '/tokens-icons/eth.png' 
    },
    { 
      name: 'Bitcoin', 
      symbol: 'BTC', 
      value: '45,678.90', 
      quantity: '0.567',
      change: 1.23, 
      icon: '/tokens-icons/btc.png' 
    },
    // Ajouter plus d'assets selon besoin
  ];

  const recentTransactions = [
    { type: 'Swap', from: 'KAS', to: 'ETH', amount: '100', date: '2024-03-20' },
    { type: 'Add Liquidity', token0: 'KAS', token1: 'ETH', amount: '500', date: '2024-03-19' },
    // Ajouter plus de transactions selon besoin
  ];

  // Mock data pour les graphiques
  const portfolioHistory = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Portfolio Value',
        data: [35000, 42000, 39000, 45000, 48000, 49258],
        borderColor: '#10B981',
        backgroundColor: 'rgba(16, 185, 129, 0.1)',
        fill: true,
        tension: 0.4
      }
    ]
  };

  const distributionData = {
    labels: ['KAS', 'ETH', 'BTC'],
    datasets: [{
      data: [1234.56, 2345.67, 45678.90],
      backgroundColor: [
        '#10B981',
        '#6366F1',
        '#F59E0B'
      ],
      borderWidth: 0
    }]
  };

  const lineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: '#1F2937',
        titleColor: '#fff',
        bodyColor: '#fff',
        padding: 12,
        borderColor: '#374151',
        borderWidth: 1,
        callbacks: {
          label: function(context) {
            return `$${context.parsed.y.toLocaleString()}`;
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: '#9CA3AF'
        }
      },
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: '#9CA3AF',
          callback: (value) => `$${value.toLocaleString()}`
        }
      }
    }
  };

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '70%',
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          color: '#9CA3AF',
          usePointStyle: true,
          padding: 20
        }
      },
      tooltip: {
        backgroundColor: '#1F2937',
        titleColor: '#fff',
        bodyColor: '#fff',
        padding: 12,
        callbacks: {
          label: function(context) {
            return `$${context.parsed.toLocaleString()}`;
          }
        }
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col">
      <Navbar />
      
      <main className="flex-grow container mx-auto px-4 py-8 max-w-7xl mt-16">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8"
        >
          <StatCard 
            icon={<FaWallet size={24} />}
            title="Total Balance"
            value="$49,258.13"
          />
          <StatCard 
            icon={<FaChartLine size={24} />}
            title="24h Change"
            value="+$1,234.56"
          />
          <StatCard 
            icon={<FaExchangeAlt size={24} />}
            title="Total Trades"
            value="123"
          />
          <StatCard 
            icon={<FaHistory size={24} />}
            title="Active Positions"
            value="5"
          />
        </motion.div>

        {/* Graphiques */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
          {/* Portfolio Value Chart */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-gray-800/50 rounded-2xl p-6"
          >
            <h2 className="text-xl font-bold text-white mb-6">Portfolio Value</h2>
            <div className="h-[300px]">
              <Line options={lineOptions} data={portfolioHistory} />
            </div>
          </motion.div>

          {/* Portfolio Distribution */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-gray-800/50 rounded-2xl p-6"
          >
            <h2 className="text-xl font-bold text-white mb-6">Portfolio Distribution</h2>
            <div className="h-[300px] flex items-center justify-center">
              <Doughnut options={doughnutOptions} data={distributionData} />
            </div>
            <div className="flex justify-center gap-4 mt-4">
              {distributionData.labels.map((entry, index) => (
                <div key={index} className="flex items-center gap-2">
                  <div 
                    className="w-3 h-3 rounded-full" 
                    style={{ backgroundColor: distributionData.datasets[0].backgroundColor[index] }}
                  />
                  <span className="text-gray-400 text-sm">{entry}</span>
                </div>
              ))}
            </div>
          </motion.div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Portfolio Section */}
          <div className="lg:col-span-2">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-gray-800/50 rounded-2xl p-6"
            >
              <h2 className="text-xl font-bold text-white mb-6">Your Portfolio</h2>
              <div className="space-y-4">
                {assets.map((asset, index) => (
                  <AssetCard 
                    key={asset.symbol} 
                    asset={asset}
                  />
                ))}
              </div>
            </motion.div>
          </div>

          {/* Recent Transactions */}
          <div className="lg:col-span-1">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-gray-800/50 rounded-2xl p-6"
            >
              <h2 className="text-xl font-bold text-white mb-6">Recent Transactions</h2>
              <div className="space-y-4">
                {recentTransactions.map((tx, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: index * 0.1 }}
                    className="bg-gray-800 rounded-xl p-4 border border-gray-700"
                  >
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="text-white font-semibold">{tx.type}</p>
                        <p className="text-sm text-gray-400">
                          {tx.type === 'Swap' 
                            ? `${tx.from} → ${tx.to}`
                            : `${tx.token0} + ${tx.token1}`
                          }
                        </p>
                      </div>
                      <div className="text-right">
                        <p className="text-white">${tx.amount}</p>
                        <p className="text-sm text-gray-400">{tx.date}</p>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default Dashboard; 