import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <nav className="fixed w-full bg-gray-900/95 backdrop-blur-sm z-50 py-4">
      <div className="container mx-auto px-4 flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <span className="text-2xl font-bold text-emerald-400">KASPACHO</span>
        </Link>
        <div className="flex gap-6 items-center">
          <Link to="/" className="text-white hover:text-emerald-400">Home</Link>
          <Link to="/dashboard" className="text-white hover:text-emerald-400">Dashboard</Link>
          <Link to="/pool" className="text-white hover:text-emerald-400">Pool</Link>
          <Link to="/pool/add" className="text-white hover:text-emerald-400">Add Liquidity</Link>
          <Link to="/analytics" className="text-white hover:text-emerald-400">Analytics</Link>
          <a href="/" className="text-white hover:text-emerald-400">Docs</a>
          <Link 
            to="/swap"
            className="bg-emerald-400 text-gray-900 px-4 py-2 rounded-lg hover:bg-emerald-500 transition-colors"
          >
            Launch App
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar; 