import React, { useState } from 'react';
import { FaChevronLeft, FaChevronDown, FaInfoCircle, FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import TokenSelector from '../components/TokenSelector';
import { motion, AnimatePresence } from 'framer-motion';

const TOKENS = [
  { symbol: 'BTC', name: 'Bitcoin', image: '/tokens-icons/btc.png', balance: 0.5 },
  { symbol: 'ETH', name: 'Ethereum', image: '/tokens-icons/eth.png', balance: 2.5 },
  { symbol: 'BNB', name: 'Binance Coin', image: '/tokens-icons/bnb.png', balance: 10.0 },
  { symbol: 'USDT', name: 'Tether', image: '/tokens-icons/usdt.png', balance: 5000.00 },
  { symbol: 'USDC', name: 'USD Coin', image: '/tokens-icons/usdc.png', balance: 5000.00 },
  { symbol: 'DAI', name: 'Dai', image: '/tokens-icons/dai.png', balance: 5000.00 },
  { symbol: 'KAS', name: 'Kaspa', image: '/tokens-icons/kas.svg', balance: 1500.45 },
  { symbol: 'KASPACHO', name: 'Kaspacho', image: '/tokens-icons/kas.svg', balance: 2500.00 },
];

function AddLiquidity() {
  const [token0, setToken0] = useState(TOKENS[0]);
  const [token1, setToken1] = useState(TOKENS[1]);
  const [amount0, setAmount0] = useState('');
  const [amount1, setAmount1] = useState('');
  const [selectorOpen, setSelectorOpen] = useState(false);
  const [activeField, setActiveField] = useState(null); // 'token0' ou 'token1'

  const handleOpenSelector = (field) => {
    setActiveField(field);
    setSelectorOpen(true);
  };

  const handleSelectToken = (token) => {
    if (activeField === 'token0') {
      if (token.symbol === token1.symbol) {
        setToken1(token0);
      }
      setToken0(token);
    } else {
      if (token.symbol === token0.symbol) {
        setToken0(token1);
      }
      setToken1(token);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col">
      <Navbar />
      
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="flex-grow flex items-center justify-center px-4"
      >
        <div className="container mx-auto max-w-lg">
          <div className="bg-gray-800 rounded-2xl p-6 border border-gray-700">
            {/* Token 0 Input */}
            <div className="bg-gray-900 rounded-xl p-4">
              <div className="flex justify-between mb-2">
                <label className="text-sm text-gray-400">Input</label>
                <span className="text-sm text-gray-400">Balance: {token0.balance}</span>
              </div>
              <div className="flex gap-2">
                <AnimatePresence mode="wait">
                  <motion.div
                    key={token0.symbol}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    className="flex-1"
                  >
                    <input
                      type="number"
                      value={amount0}
                      onChange={(e) => setAmount0(e.target.value)}
                      placeholder="0.0"
                      className="w-full bg-gray-800 rounded-xl px-4 py-2 text-white text-xl"
                    />
                  </motion.div>
                </AnimatePresence>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => handleOpenSelector('token0')}
                  className="flex items-center gap-2 bg-gray-800 rounded-xl px-4 py-2"
                >
                  <img src={token0.image} alt={token0.symbol} className="w-6 h-6 rounded-full" />
                  <span className="text-white">{token0.symbol}</span>
                  <FaChevronDown className="text-gray-400" />
                </motion.button>
              </div>
            </div>

            {/* Plus Icon */}
            <div className="flex justify-center -my-3 relative z-10">
              <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
                className="bg-gray-900 p-2 text-emerald-400"
              >
                <FaPlus />
              </motion.div>
            </div>

            {/* Token 1 Input */}
            <div className="bg-gray-900 rounded-xl p-4">
              <div className="flex justify-between mb-2">
                <label className="text-sm text-gray-400">Input</label>
                <span className="text-sm text-gray-400">Balance: {token1.balance}</span>
              </div>
              <div className="flex gap-2">
                <AnimatePresence mode="wait">
                  <motion.div
                    key={token1.symbol}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    className="flex-1"
                  >
                    <input
                      type="number"
                      value={amount1}
                      onChange={(e) => setAmount1(e.target.value)}
                      placeholder="0.0"
                      className="w-full bg-gray-800 rounded-xl px-4 py-2 text-white text-xl"
                    />
                  </motion.div>
                </AnimatePresence>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => handleOpenSelector('token1')}
                  className="flex items-center gap-2 bg-gray-800 rounded-xl px-4 py-2"
                >
                  <img src={token1.image} alt={token1.symbol} className="w-6 h-6 rounded-full" />
                  <span className="text-white">{token1.symbol}</span>
                  <FaChevronDown className="text-gray-400" />
                </motion.button>
              </div>
            </div>

            {/* Add Liquidity Button */}
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="w-full mt-6 bg-emerald-400 hover:bg-emerald-500 text-gray-900 font-bold py-4 px-6 rounded-xl transition-colors"
            >
              Add Liquidity
            </motion.button>
          </div>
        </div>
      </motion.div>

      <AnimatePresence>
        {selectorOpen && (
          <TokenSelector
            isOpen={selectorOpen}
            onClose={() => setSelectorOpen(false)}
            onSelect={handleSelectToken}
            selectedToken={activeField === 'token0' ? token0 : token1}
          />
        )}
      </AnimatePresence>

      <Footer />
    </div>
  );
}

export default AddLiquidity; 