import React, { useState } from 'react';
import { FaPlus, FaChartLine } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const LIQUIDITY_POOLS = [
  {
    id: 1,
    token0: { symbol: 'KAS', image: '/tokens-icons/kas.svg' },
    token1: { symbol: 'KASPACHO', image: '/tokens-icons/kas.svg' },
    tvl: 2500000,
    apr: 125,
    volume24h: 150000,
    myLiquidity: 5000,
    myShare: 0.2
  },
  {
    id: 2,
    token0: { symbol: 'KAS', image: '/tokens-icons/kas.svg' },
    token1: { symbol: 'USDT', image: '/tokens-icons/usdt.png' },
    tvl: 1800000,
    apr: 85,
    volume24h: 95000,
    myLiquidity: 0,
    myShare: 0
  },
  {
    id: 3,
    token0: { symbol: 'KASPACHO', image: '/tokens-icons/kas.svg' },
    token1: { symbol: 'USDT', image: '/tokens-icons/usdt.png' },
    tvl: 900000,
    apr: 95,
    volume24h: 75000,
    myLiquidity: 1200,
    myShare: 0.13
  }
];

function Pool() {
  const [activeTab, setActiveTab] = useState('all');

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3
      }
    }
  };

  const formatNumber = (num) => {
    if (num >= 1000000) {
      return `$${(num / 1000000).toFixed(1)}M`;
    } else if (num >= 1000) {
      return `$${(num / 1000).toFixed(1)}K`;
    }
    return `$${num.toFixed(2)}`;
  };

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col">
      <Navbar />
      
      <motion.div 
        className="flex-grow container mx-auto px-4 py-8 max-w-6xl flex items-center justify-center"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="w-full">
          {/* Header */}
          <motion.div 
            className="flex justify-between items-center mb-8"
            variants={itemVariants}
          >
            <h1 className="text-2xl font-bold text-white">Liquidity Pools</h1>
            <Link 
              to="/pool/add" 
              className="flex items-center gap-2 bg-emerald-400 hover:bg-emerald-500 text-gray-900 font-bold py-2 px-4 rounded-xl"
            >
              <FaPlus />
              Add Liquidity
            </Link>
          </motion.div>

          {/* Tabs */}
          <motion.div 
            className="flex gap-4 mb-6"
            variants={itemVariants}
          >
            <button
              onClick={() => setActiveTab('all')}
              className={`px-4 py-2 rounded-xl font-semibold transition-colors duration-200 ${
                activeTab === 'all'
                  ? 'bg-emerald-400 text-gray-900'
                  : 'bg-gray-800 text-gray-400 hover:bg-gray-700'
              }`}
            >
              All Pools
            </button>
            <button
              onClick={() => setActiveTab('my')}
              className={`px-4 py-2 rounded-xl font-semibold transition-colors duration-200 ${
                activeTab === 'my'
                  ? 'bg-emerald-400 text-gray-900'
                  : 'bg-gray-800 text-gray-400 hover:bg-gray-700'
              }`}
            >
              My Pools
            </button>
          </motion.div>

          {/* Pools List with AnimatePresence for smooth transitions */}
          <AnimatePresence mode="wait">
            <motion.div 
              key={activeTab}
              className="grid gap-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              {LIQUIDITY_POOLS
                .filter(pool => activeTab === 'all' || pool.myLiquidity > 0)
                .map((pool, index) => (
                  <motion.div
                    key={pool.id}
                    variants={itemVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ delay: index * 0.1 }}
                    className="bg-gray-800 rounded-xl p-6 hover:bg-gray-750 transition-colors duration-200"
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-4">
                        <div className="flex items-center -space-x-2">
                          <img src={pool.token0.image} alt={pool.token0.symbol} className="w-8 h-8 rounded-full z-10" />
                          <img src={pool.token1.image} alt={pool.token1.symbol} className="w-8 h-8 rounded-full" />
                        </div>
                        <span className="text-white font-semibold">
                          {pool.token0.symbol}/{pool.token1.symbol}
                        </span>
                      </div>

                      <div className="flex items-center gap-8">
                        <div className="text-right">
                          <div className="text-gray-400 text-sm">TVL</div>
                          <div className="text-white font-semibold">{formatNumber(pool.tvl)}</div>
                        </div>
                        <div className="text-right">
                          <div className="text-gray-400 text-sm">APR</div>
                          <div className="text-emerald-400 font-semibold">{pool.apr}%</div>
                        </div>
                        <div className="text-right">
                          <div className="text-gray-400 text-sm">24h Volume</div>
                          <div className="text-white font-semibold">{formatNumber(pool.volume24h)}</div>
                        </div>
                        {pool.myLiquidity > 0 && (
                          <div className="text-right">
                            <div className="text-gray-400 text-sm">My Share</div>
                            <div className="text-white font-semibold">{pool.myShare.toFixed(2)}%</div>
                          </div>
                        )}
                        <button className="bg-gray-700 hover:bg-gray-600 p-2 rounded-lg transition-colors duration-200">
                          <FaChartLine className="text-emerald-400" />
                        </button>
                      </div>
                    </div>
                  </motion.div>
                ))}
            </motion.div>
          </AnimatePresence>
        </div>
      </motion.div>

      <Footer />
    </div>
  );
}

export default Pool; 